import { FC } from "react";
import { View } from "react-native";
import { Divider, IconButton } from "react-native-paper";
import { A } from "@expo/html-elements";

import DisplayIfPresent from "../shared/DisplayIfPresent";
import Score from "../shared/Score";
import { Business } from "../../types";
import Websites from "./Websites";

interface BusinessInformationProps {
  business: Business;
}

interface SocialLinkProps {
  url?: string;
  icon: "facebook" | "twitter";
}

const SocialLink: FC<SocialLinkProps> = ({ url, icon }) => {
  if (!url) return null;

  return (
    <A target="_blank" rel="noopener noreferrer" href={url}>
      <IconButton icon={icon} size={32} />
    </A>
  );
};

const BusinessInformation: FC<BusinessInformationProps> = ({
  business: {
    phoneNumbers = [],
    websites = [],
    yearBusinessOpened,
    firstSeen,
    lastSeen,
    createdAt,
    metadata: {
      Score: score,
      FB_URL: fbUrl,
      Twitter: twitterUrl,
      Parcel_ID: parcelId,
      Unique_ID: uniqueId,
      taxroll: {
        originalSitusFullAddress,
        contactFullAddress,
        contactPhone,
      } = {},
    },
  },
}) => {
  const primaryPhone = phoneNumbers[0];

  const sectionOne =
    [primaryPhone, websites[0], fbUrl, twitterUrl].filter(Boolean).join("").length > 0;
  const sectionTwo =
    [parcelId, uniqueId, yearBusinessOpened, firstSeen, lastSeen].filter(Boolean).join("")
      .length > 0;
  const sectionThree =
    [ originalSitusFullAddress,
      contactFullAddress,
      contactPhone,
    ]
      .filter(Boolean)
      .join("").length > 0;

  if (!sectionOne && !sectionTwo && !sectionThree) return null;

  const createdAtStr = createdAt ? createdAt.toDate().toISOString().slice(0, 10) : null;

  return (
    <>
      {sectionOne && <Divider style={{ marginTop: 6, marginBottom: 6 }} />}
      {score && (
        <Score score={Number(score)} label="bold" />
      )}

      <DisplayIfPresent label="Phone" text={primaryPhone} />
      <Websites websites={websites} />

      <View style={{ flexDirection: "row" }}>
        <SocialLink icon="facebook" url={fbUrl} />
        <SocialLink icon="twitter" url={twitterUrl} />
      </View>

      {sectionTwo && <Divider style={{ marginTop: 6, marginBottom: 6 }} />}

      <DisplayIfPresent label="Parcel ID" text={parcelId} />
      <DisplayIfPresent label="Unique ID" text={uniqueId} />
      <DisplayIfPresent label="Year Opened" text={yearBusinessOpened} />
      <DisplayIfPresent label="First Seen" text={firstSeen} />
      <DisplayIfPresent label="Last Seen" text={lastSeen} />
      <DisplayIfPresent label="Data As of" text={createdAtStr} />

      {sectionThree && <Divider style={{ marginTop: 6, marginBottom: 6 }} />}

      <DisplayIfPresent label="Original Situs Address" text={originalSitusFullAddress} />
      <DisplayIfPresent label="Contact Address" text={contactFullAddress} />
      <DisplayIfPresent label="Contact Phone" text={contactPhone} />

    </>
  );
};

export default BusinessInformation;
