import { FieldValue, Timestamp } from "firebase/firestore";

import { Address } from "./address";
import * as statuses from "./subjectStatuses";

export const BusinessCategories = [
  "New",
  "Closed",
  "Field Review",
  "Data Review",
  "Home Based",
  "Active",
] as const;

export type BusinessCategory = (typeof BusinessCategories)[number];

// TODO: (Residence|Business)Status type fields are deprecated. Use
// SubjectStatus fields instead.
export type BusinessStatus = statuses.SubjectStatus;

export type BusinessStatuses = statuses.SubjectStatuses;

export const BusinessStatusesTransient = [statuses.SubjectStatuses.NONE] as const;
export const BusinessStatusesTerminal = [
  statuses.SubjectStatuses.DISMISSED,
  statuses.SubjectStatuses.CASE_CREATED,
] as const;
// END DEPRECATED TYPES

export type BusinessAttributes = Address & {
  name: string;
  category: BusinessCategory;
  status: BusinessStatus;
  latitude: string;
  longitude: string;
  yearBusinessOpened: string;
  firstSeen?: string;
  lastSeen?: string;
  createdAt: FieldValue;
  metadata: MetaData;
  altNames: string[];
  phoneNumbers: string[];
  websites: string[];
  altName: string;
  phoneNumber: string;
  website: string;
  assigneeId?: string;
  [key: string]: string | string[] | MetaData | undefined | FieldValue;
};

export type Business = BusinessAttributes & {
  id: string;
  createdAt: Timestamp;
};

export type MetaData = {
  Bus_Place_ID: string;
  Clean_Address?: string;
  DC_Source_ID?: string;
  DC_Status?: string;
  FB_URL?: string;
  Parcel_ID?: string;
  Unique_ID?: string;
  Res_Place_ID?: string;
  Score?: string;
  Status?: string;
  Twitter?: string;
  taxroll?: TaxRoll;
};

export type TaxRoll = {
  originalSitusFullAddress: string;
  contactFullAddress?: string;
  contactAddress?: string;
  contactUnit?: string;
  contactCity?: string;
  contactStateAbbr?: string;
  contactZip?: string;
  contactPhone?: string;
}
